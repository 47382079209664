import { FaLinkedin, FaGithubSquare, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa'
import { FiLinkedin, FiGithub, FiInstagram, FiFacebook } from 'react-icons/fi'
import './About.css'

const About = () => {
    return (
        <div className="about">
            <div className="transition-to-about"></div>
            <h1 className="about-heading">About Me</h1>
            <div className="about-profile">
                <div className="profile-container">
                    <img src={process.env.PUBLIC_URL + '/cartoonProfile.jpg'} />
                    <div className="about-links">
                        <a href="https://www.linkedin.com/in/danielyhan/" target="_blank">
                            <FiLinkedin />
                        </a>
                        <a href="https://www.github.com/hysstory" target="_blank">
                            <FiGithub />
                        </a>
                        <a href="https://www.instagram.com/hanieldan_/" target="_blank">
                            <FiInstagram />
                        </a>
                        <a href="https://www.facebook.com/dyh92" target="_blank">
                            <FiFacebook />
                        </a>
                    </div>
                </div>
            </div>
            <div className="about-text">
                <p>
                    Hello! <br/> my name is Daniel Han, <br/>
                    a software engineer based out of Atlanta, <br/>
                    looking to grow in knowledge and character <br/>
                    through challenging and meaningful work<br/>
                    <div className="space"></div>
                    in the meantime, I am coming up with ideas,<br/>
                    and making applications<br/>
                    to help the people around me <br/>
                    in little aspects of their life <br/>
                    <div className="space"></div>
                    as such, I hope to make the world a better place <br/>
                    one line of code at a time

                </p>
            </div>
        </div>
    )
}

export default About
